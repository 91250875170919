/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .p {
    &-#{$i} {
      padding: #{$i}px !important;
    }

    &-t-#{$i} {
      padding-top: #{$i}px !important;
    }

    &-b-#{$i} {
      padding-bottom: #{$i}px !important;
    }

    &-l-#{$i} {
      padding-left: #{$i}px !important;
    }

    &-r-#{$i} {
      padding-right: #{$i}px !important;
    }
    &-x-#{$i} {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
    &-y-#{$i} {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }
  }

  .m {
    &-#{$i} {
      margin: #{$i}px !important;
    }

    &-t-#{$i} {
      margin-top: #{$i}px !important;
    }

    &-b-#{$i} {
      margin-bottom: #{$i}px !important;
    }

    &-l-#{$i} {
      margin-left: #{$i}px !important;
    }

    &-r-#{$i} {
      margin-right: #{$i}px !important;
    }
    &-x-#{$i} {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }
    &-y-#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }
  }

  $i: $i + 5;
}

/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
$i: 6;

@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px !important;
  }

  $i: $i + 2;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;

@while $i<=900 {
  .f-w-#{$i} {
    font-weight: #{$i} !important;
  }

  $i: $i + 100;
}

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
$i: 10;

@while $i<=150 {
  .wid-#{$i} {
    width: #{$i}px !important;
  }

  .hei-#{$i} {
    height: #{$i}px;
  }

  $i: $i + 5;
}

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
$i: 1;

@while $i<=8 {
  .b-wid-#{$i} {
    border-width: #{$i}px;
  }

  $i: $i + 1;
}

/*====== border-width css ends ======*/
/*====== border-radius css start =====*/
$i: 5;

@while $i<=50 {
  .b-rad-#{$i} {
    border-radius: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== border-radius css ends ======*/
