.navbar-content {
  &.sidenav-horizontal {
    display: flex;
    height: auto;
    border-top: 1px solid var(--accent-300);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(19, 25, 32, 0.08) 0px 2px 8px;

    .sidenav-horizontal-next,
    .sidenav-horizontal-prev {
      position: relative;
      display: block;
      flex: 0 0 auto;
      width: 2.25rem;
      background-color: var(--accent-50);
      z-index: 1030;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border: 2px solid;
        border-top: 0;
      }

      &.disabled {
        cursor: default !important;
        opacity: 0;
      }
    }

    .sidenav-horizontal-prev::after {
      border-right: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .sidenav-horizontal-next::after {
      border-left: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    .sidenav-horizontal-wrapper {
      height: 50px;
      flex: 0 1 100%;
      width: 0;
      display: flex;
      align-items: center;

      .sidenav:not(.sidenav-no-animation) & .sidenav-inner {
        transition: margin 0.2s;
      }

      .coded-inner-navbar {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        white-space: nowrap;
        transition: all 0.5s ease-in-out;

        .coded-menu-caption {
          display: none;
        }

        > app-menu-collapse,
        > app-menu-item {
          position: relative;
          display: inline-block;
          list-style: outside none none;
        }

        app-menu-item > li,
        > app-menu-group-horizontal > app-menu-collapse > li {
          display: inline-block;
          list-style: outside none none;
          margin: 0;
          padding: 9px 0px;
          position: relative;

          &.active,
          &.coded-trigger {
            > a {
              color: var(--primary-500);
              font-weight: 500;

              &:before {
                background: var(--primary-500);
              }
            }
          }

          &:hover:not(.coded-trigger, .active) {
            > a {
              &:before {
                background: var(--accent-500);
              }
            }
          }

          > a {
            margin: 0;
            position: relative;
            padding: 15px;
            color: var(--accent-600);

            > .coded-micon {
              font-size: 18px;
              margin-right: 15px;
              vertical-align: middle;
              text-align: center;
            }

            > .coded-mtext {
              position: relative;
              top: 0;
              margin-right: 5px;
              vertical-align: middle;
            }

            &::before {
              content: '';
              border-radius: 8px;
              position: absolute;
              inset: 2px;
              opacity: 0.1;
            }
          }
        }

        > app-menu-group-horizontal > app-menu-collapse > li {
          &.coded-hasmenu {
            .coded-submenu {
              app-menu-item > li {
                padding: 0px;

                > a {
                  text-align: left;
                  padding: 10px 20px 10px 48px;
                  margin: 0;
                  display: block;

                  &::before {
                    content: '';
                    border-radius: 50%;
                    position: absolute;
                    top: 18px;
                    width: 5px;
                    height: 5px;
                    left: 28px;
                    background: var(--accent-600);
                    opacity: 0.8;
                    transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
                  }
                }

                &.active {
                  > a {
                    &::before {
                      background: var(--primary-500);
                    }
                  }
                }
              }

              app-menu-collapse li {
                > a {
                  text-align: left;
                  padding: 10px 20px 10px 48px;
                  margin: 0;
                  display: block;
                }
              }
            }
          }
        }

        li {
          &.coded-hasmenu {
            > a {
              &::after {
                content: '\ea62';
                font-family: 'tabler-icons';
                font-size: 15px;
                border: none;
                transition: 0.3s ease-in-out;
                position: relative;
                top: 2px;
                right: 0;
              }
            }

            &.coded-trigger {
              > a {
                &::after {
                  content: '\ea5f';
                  font-family: 'tabler-icons';
                }
              }
            }

            .coded-submenu {
              li.coded-hasmenu {
                > a {
                  &::after {
                    content: '\ea62';
                    font-family: 'tabler-icons';
                    font-size: 15px;
                    border: none;
                    transition: 0.3s ease-in-out;
                    position: relative;
                    top: 2px;
                    left: 40px;
                  }
                }

                &.coded-trigger {
                  > a {
                    &::after {
                      content: '\ea5f';
                      font-family: 'tabler-icons';
                    }
                  }
                }
              }
            }
          }
        }
      }

      app-menu-group-horizontal {
        display: inline-flex;
      }

      app-menu-group-horizontal app-menu-collapse > li,
      app-menu-group-horizontal > app-menu-collapse > li {
        overflow: visible;
      }

      .coded-inner-navbar .coded-hasmenu {
        .coded-submenu {
          opacity: 0;
          visibility: hidden;
          transform-origin: 50% 50%;
          transition:
            transform 0.3s,
            opacity 0.3s;
          transform-style: preserve-3d;
          transform: rotateX(-90deg);
          position: absolute;
          min-width: 250px;
          display: block;
          z-index: 1;
          top: 100%;
          list-style: outside none none;
          margin: 0;
          border-radius: 4px;
          padding: 15px;
          background: var(--accent-50);
          box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);

          app-menu-collapse li {
            .coded-submenu {
              right: 100%;
              top: 70px;
            }
          }

          li {
            display: block;

            &:after {
              display: none;
            }
          }
        }

        &.coded-trigger {
          > .coded-submenu {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
          }
        }
      }
    }

    .coded-inner-navbar {
      app-menu-item > li {
        &.coded-trigger:after,
        &.active:after {
          top: auto !important;
          height: 2px !important;
        }
      }
    }
  }
}
