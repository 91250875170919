// ### Novotecs values ###
// ag-grid
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-balham.css";
@import '~ag-grid-community/styles//ag-theme-material.css';

@import "~@ng-select/ng-select/themes/material.theme.css";
// @import "~@ng-select/ng-select/themes/default.theme.css";

//@import "~@ng-select/ng-select/themes/default.theme.css";


.ag-root-wrapper { border-radius: 4px }

// ng-select
.ng-select.ng-select-single .ng-select-container {
  min-height: 28px !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 28px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  height: 50px;
  // margin-left: 7px !important;
  padding-left: 12px !important;
}

.ng-select .ng-select-container .ng-value-container {
  padding: 2px 6px;
}

.ng-placeholder {
  top: 12px;
  background-color: white;
  padding: 2px 4px;
}

.ng-select-container {
  border: 1px solid #dbe0e4;
  border-radius: 4px;
}

.ng-select .ng-select-container {
  overflow: visible !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top: none !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--mat-option-selected-state-label-text-color);
}

.ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.6125em) scale(.75) perspective(100px) translateZ(.001px) !important;
}

.form-floating {
  position: relative;

  .ng-select-container {
      height: calc(3.5rem + 2px) !important;
      line-height: 1.25;
  }

  label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; // allow textareas
      padding: 1rem 0.75rem;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      border: 1px solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .ng-select-container {
      padding-top: 1.625rem;
      padding-bottom: .625rem;

      .ng-input {
          top: unset !important;
      }
  }

  ng-select {
      ~label {
          opacity: 0.65;
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          z-index: 1001;
      }


      &:focus .ng-select-container {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }

      &.is-valid .ng-select-container {
          border-color: #198754 !important;
      }

      &.is-valid.ng-select-focused .ng-select-container {
          box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);
      }

      &.is-invalid .ng-select-container {
          border-color: #dc3545 !important;

      }

      &.is-invalid.ng-select-focused .ng-select-container {
          box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
      }
  }
}

.ng-select .ng-clear-wrapper {
  // height: 40px !important;
  right: 0 !important;
}

.ng-select .ng-select-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-bottom: 20px;
}

.ng-value-container {
  margin-left: 2px !important;
}

.ng-select div, .ng-select input, .ng-select span {
  box-sizing: content-box !important;
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  bottom: 2px;
  margin-right: 10px;
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  bottom: 0;
}

.ng-select .ng-select-container {
  margin-bottom: 0 !important;
}

.ng-error > .ng-select-container > .ng-value-container > .ng-placeholder {
  color: var(--mdc-outlined-text-field-error-label-text-color);
}



// status
.invoice-status {
  padding: 0.25em 0.9em;
  border-radius: 8px;
}

.status-label .sub-head {
  min-width: 100px;
  line-height: 1.2;
  text-align: center;
  display: block;
  margin-top: 10px;
}

.status-label .bg-abn {background-color: #000;}
.status-label .bg-fin {background-color: #4caf50;}
.status-label .bg-new {background-color: #00bcd4;}
.status-label .bg-run {background-color: #ff9800;}
.status-label .bg-man {background-color: #3f51b5;}
.status-label .bg-asg {background-color: #9370db;}
.status-label .bg-aca {background-color: #e91e63;}
.status-label .bg-pod {background-color: #9acd32;}
.status-label .bg-cls {background-color: #6c757d;}


// buttons
.btn {
  height: 28px !important;
}

// animation 
.position-none {
  left: -2000px;
  position: absolute;
  display: none;
}

// buttons action
.overflow-visible {
  overflow: visible !important;
}

// line ag-grid
.mt-5 {
  margin-top: 10px;
}

//buttons validation
.mr-5{
  margin-right: 10px;
}

// buttons swal alert
button.swal2-confirm.btn.btn-success {
  margin-right: 10px;
}

/*
    NgSelect
*/
.ng-select .ng-clear-wrapper {
  // height: 40px !important;
  right: 0 !important;
}

.ng-select .ng-select-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-bottom: 20px;
}

.ng-value-container {
  margin-left: 7px !important;
}

.ng-select div, .ng-select input, .ng-select span {
  box-sizing: content-box !important;
}


// Status
.sub-head {
  border-radius: 8px;
  font-size: 12px;
  background: var(--primary-500);
  // border: 1px solid var(--primary-500);
  padding: 5px 10px;
  // color: var(--primary-500);
  color: white;

  &.info {
    background: #00A1DF;
  }

  &.success {
    background: var(--success-500);
    // border: 1px solid var(--success-500);
    // color: var(--success-500);
  }

  &.warning {
    background: var(--warning-500);
    // border: 1px solid var(--warning-500);
    // color: var(--warning-500);
  }

  &.warn {
    background: var(--warn-500);
    // border: 1px solid var(--warn-500);
    // color: var(--warn-500);
  }

  &.secondary {
    // background: #BEC8D0;
    background: #131920;
    // border: 1px solid #131920;
    // color: #131920;
  }

  &.purple {
    // background: #E9E2F9;
    background: purple;
    // border: 1px solid #6610F2;
    // color: #6610F2;
  }

  &.indigo {
    // background: #E9E2F9;
    background: indigo;
    // border: 1px solid #6610F2;
    // color: #6610F2;
  }

  &.orange {
    // background: #E9E2F9;
    background: orange;
    color: black;
    // border: 1px solid #6610F2;
    // color: #6610F2;
  }

  &.red {
    // background: #E9E2F9;
    background: red;
    // border: 1px solid #6610F2;
    // color: #6610F2;
  }
}

// Datepicker default selected
.mat-calendar-body-selected {
  color: white !important;
}

// Loader
.loading {
  visibility: hidden;
}

// ag-grid
.table-ag-grid {
  height: calc(100vh - 250px);
}

.table-ag-grid-with-filter {
  height: calc(100vh - 280px);
}

.table-ag-grid-tab {
  height: calc(100vh - 350px);
}

.table-ag-grid-client {
  height: calc(100vh - 350px);
}


// badge
span.badge {
  color: white;
  padding: 4px;
  border-radius: 7px;
}

.badge-danger {
  background-color: red;
}

.badge-success {
  background-color: green;
}


//Swal
/** GED */
.swal-ged-iframe {
  width: 100%;
  height: 82vh;
  border: 1px solid lightgray;
  margin-bottom: 1px;
}


// menu for client
app-menu-group-horizontal > app-menu-item >  li.nav-item > a > span {
  color: #aaa !important;
}

app-menu-group-horizontal > app-menu-item > li.nav-item.active > a > span {
  color: var(--primary-500) !important;
}

.ag-theme-material {
  font-size: .775rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}

.ag-theme-material .ag-header {
  font-size: .875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}

.ag-theme-material .ag-cell {
  line-height: 2.2rem;
}

.ag-theme-material .ag-cell, 
.ag-theme-material .ag-header-cell{
  padding-left: 10px;
  padding-right: 10px;
}


// grid count
.grid-count {
  color: var(--primary-500);
  font-style: italic;
  margin-left: 5px;
}


// New design adjustment
// Tab menu
.mat-mdc-tab-header {
  background-color: #00a1df40;
  border-radius: 8px;
}

// Card
.mat-mdc-card {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

// Sidebar
.pc-sidebar .navbar-wrapper {
  background-color: white;
  font-weight: bold;
}

// Top navbar
.pc-header .header-wrapper {
  background-color: white;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, .12), 0 7px 10px -5px rgba(0, 0, 0, .15);
}

// Card title
.card-head h5 {
  font-size: large;
}

// Logo
.pc-sidebar img.logo-retina-long {
  margin-top: 30px !important;
  margin-left: 45px !important;
  margin-bottom: 30px !important;
  height: 50px;
}


// buttons
.mat-delete {
  //color: #f44336;

  color: white;
  border: none;
  background-color: var(--warn-500);
  margin-top: 8px;
  border-radius: 6px;
}

.mat-edit, .mat-new {
  //color: #4caf50;

  color: white;
  border: none;
  background-color: var(--primary-500);
  margin-top: 8px;
  border-radius: 6px;
}

.mat-view {
  //color: #00bcd4;

  color: white;
  border: none;
  background-color: var(--primary-500);
  margin-top: 8px;
  border-radius: 6px;
}
// mat-mdc-tooltip-trigger mat-edit mat-mdc-button-base

//disabled field color
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input,
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
	color: #0000009c !important;
}

// Main body
body .mat-drawer-container {
  background-color: #eee !important;
}


// Login page
.auth-main .auth-wrapper .auth-form .auth-form-card {
  max-width: 370px !important;
}

.auth-main .auth-wrapper .auth-form {
  display: flex;
  justify-content: center;
  margin-top: calc(50vh - 240px);
}

// Ag-grid 
// Action
.ag-center-aligned-header .ag-header-cell-label {
  justify-content: center;
}

.ag-center-aligned {
  text-align: center;
}

// Action
app-actions .mat-icon.material-icons {
  height: 22px;
  width: 20px;
  font-size: 20px;
  line-height: 1.2;
}

// Checkbox
.mdc-checkbox {
  padding-right: 2px !important;
}

// Job
.job-search {
  background-color: var(--accent-200);
}

.dev-mode .header-wrapper {
  background-image: repeating-linear-gradient(45deg, rgb(245 44 44 / 75%), rgb(236 100 100 / 81%) 25px, rgb(247 163 175 / 85%) 25px, rgb(226 92 92 / 50%) 100px) !important;
  background-color: #30193445;
  background-size: cover;
  background-attachment: fixed;
}