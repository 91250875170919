$white: #ffffff;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$grey-theme: (
  50: #fff,
  100: #f8f9fa,
  200: #f3f5f7,
  300: #dbe0e5,
  400: #bec8d0,
  500: #8996a4,
  600: #5b6b79,
  700: #3e4853,
  800: #1d2630,
  900: #131920,
  A100: #e4f1ff,
  A200: #b2d5fe,
  A400: #7ebaff,
  A700: #64adff,
  contrast: (
    50: #1d2630,
    100: #131920,
    200: #1d2630,
    300: #3e4853,
    400: #5b6b79,
    500: #8996a4,
    600: #bec8d0,
    700: #dbe0e5,
    800: #f3f5f7,
    900: #f8f9fa,
    A100: #e4f1ff,
    A200: #b2d5fe,
    A400: #7ebaff,
    A700: #64adff
  )
);

$blue-theme: (
  50: #e1eff5,
  100: #dae6ff,
  200: #b5ccff,
  300: #90b3ff,
  400: #6b99ff,
  500: #00A1DF, //#4680ff
  600: #2281df,
  700: #1c76da,
  800: #176cd6,
  900: #0d59cf,
  A100: #ffffff,
  A200: #ffffff,
  A400: #cfd8ff,
  A700: #b6c4ff,
  contrast: (
    50: #e1eff5,
    100: #176cd6,
    200: #1c76da,
    300: #90b3ff,
    400: #2281df,
    500: #00A1DF, //#4680ff
    600: #6b99ff,
    700: #90b3ff,
    800: #b5ccff,
    900: #dae6ff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #cfd8ff,
    A700: #b6c4ff
  )
);

$indigo-theme: (
  50: #ede2fd,
  100: #d1b7fb,
  200: #b388f9,
  300: #9458f6,
  400: #7d34f4,
  500: #6610f2,
  600: #5e0ef0,
  700: #530cee,
  800: #4909ec,
  900: #3805e8,
  A100: #ffffff,
  A200: #e3ddff,
  A400: #b9aaff,
  A700: #a391ff,
  contrast: (
    50: #ede2fd,
    100: #3805e8,
    200: #4909ec,
    300: #530cee,
    400: #5e0ef0,
    500: #7123f2,
    600: #7d34f4,
    700: #9458f6,
    800: #b388f9,
    900: #d1b7fb,
    A100: #ffffff,
    A200: #e3ddff,
    A400: #b9aaff,
    A700: #a391ff
  )
);

$purple-theme: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e58c2,
  500: #673ab7,
  600: #5f34b0,
  700: #542ca7,
  800: #4a259f,
  900: #391890,
  A100: #d4c7ff,
  A200: #ad94ff,
  A400: #8661ff,
  A700: #7347ff,
  contrast: (
    50: #ede7f6,
    100: #391890,
    200: #4a259f,
    300: #542ca7,
    400: #5f34b0,
    500: #673ab7,
    600: #7e58c2,
    700: #9575cd,
    800: #b39ddb,
    900: #d1c4e9,
    A100: #d4c7ff,
    A200: #ad94ff,
    A400: #8661ff,
    A700: #7347ff
  )
);

$pink-theme: (
  50: #fce8f1,
  100: #f8c5dd,
  200: #f49fc6,
  300: #ef78af,
  400: #eb5b9d,
  500: #e83e8c,
  600: #e53884,
  700: #e23079,
  800: #de286f,
  900: #d81b5c,
  A100: #ffffff,
  A200: #ffdae5,
  A400: #ffa7c2,
  A700: #ff8db1,
  contrast: (
    50: #fce8f1,
    100: #d81b5c,
    200: #de286f,
    300: #e23079,
    400: #e53884,
    500: #e83e8c,
    600: #eb5b9d,
    700: #ef78af,
    800: #f49fc6,
    900: #f8c5dd,
    A100: #ffffff,
    A200: #ffdae5,
    A400: #ffa7c2,
    A700: #ff8db1
  )
);

$red-theme: (
  50: #fbe5e5,
  100: #f5bebe,
  200: #ee9393,
  300: #e76767,
  400: #e14747,
  500: #dc2626,
  600: #d82222,
  700: #d31c1c,
  800: #ce1717,
  900: #c50d0d,
  A100: #fff1f1,
  A200: #ffbebe,
  A400: #ff8b8b,
  A700: #ff7272,
  contrast: (
    50: #f5bebe,
    100: #c50d0d,
    200: #ce1717,
    300: #d31c1c,
    400: #d82222,
    500: #dc2626,
    600: #e14747,
    700: #e76767,
    800: #ee9393,
    900: #f5bebe,
    A100: #fff1f1,
    A200: #ffbebe,
    A400: #ff8b8b,
    A700: #ff7272
  )
);

$orange-theme: (
  50: #fff0e3,
  100: #fed8b9,
  200: #febf8a,
  300: #fea55b,
  400: #fd9137,
  500: #fd7e14,
  600: #fd7612,
  700: #fc6b0e,
  800: #fc610b,
  900: #fc4e06,
  A100: #ffffff,
  A200: #fff4f0,
  A400: #ffcdbd,
  A700: #ffbaa3,
  contrast: (
    50: #fff0e3,
    100: #fc4e06,
    200: #fc610b,
    300: #fc6b0e,
    400: #fd7612,
    500: #fd7e14,
    600: #fd9137,
    700: #fea55b,
    800: #febf8a,
    900: #fed8b9,
    A100: #ffffff,
    A200: #fff4f0,
    A400: #ffcdbd,
    A700: #ffbaa3
  )
);

$yellow-theme: (
  50: #fcf1e0,
  100: #f7dcb3,
  200: #f2c580,
  300: #edad4d,
  400: #e99c26,
  500: #e58a00,
  600: #e28200,
  700: #de7700,
  800: #da6d00,
  900: #d35a00,
  A100: #fffcfb,
  A200: #ffdcc8,
  A400: #ffbb95,
  A700: #ffab7b,
  contrast: (
    50: #fcf1e0,
    100: #d35a00,
    200: #da6d00,
    300: #de7700,
    400: #e28200,
    500: #e58a00,
    600: #e99c26,
    700: #edad4d,
    800: #f2c580,
    900: #f7dcb3,
    A100: #fffcfb,
    A200: #ffdcc8,
    A400: #ffbb95,
    A700: #ffab7b
  )
);

$green-theme: (
  50: #e6f5f0,
  100: #c0e5d9,
  200: #96d4bf,
  300: #6bc2a5,
  400: #4cb592,
  500: #2ca87f,
  600: #27a077,
  700: #21976c,
  800: #1b8d62,
  900: #107d4f,
  A100: #b0ffdb,
  A200: #7dffc4,
  A400: #4affac,
  A700: #30ffa1,
  contrast: (
    50: #e6f5f0,
    100: #107d4f,
    200: #1b8d62,
    300: #21976c,
    400: #27a077,
    500: #2ca87f,
    600: #4cb592,
    700: #6bc2a5,
    800: #96d4bf,
    900: #c0e5d9,
    A100: #b0ffdb,
    A200: #7dffc4,
    A400: #4affac,
    A700: #30ffa1
  )
);

$teal-theme: (
  50: #e4f9f3,
  100: #bcefe0,
  200: #90e4cb,
  300: #63d9b6,
  400: #41d1a7,
  500: #20c997,
  600: #1cc38f,
  700: #18bc84,
  800: #13b57a,
  900: #0ba969,
  A100: #d6ffed,
  A200: #a3ffd6,
  A400: #70ffbf,
  A700: #57ffb4,
  contrast: (
    50: #e4f9f3,
    100: #0ba969,
    200: #13b57a,
    300: #18bc84,
    400: #1cc38f,
    500: #20c997,
    600: #41d1a7,
    700: #63d9b6,
    800: #90e4cb,
    900: #bcefe0,
    A100: #d6ffed,
    A200: #a3ffd6,
    A400: #70ffbf,
    A700: #57ffb4
  )
);

$cyan-theme: (
  50: #e8f9fa,
  100: #c5eff3,
  200: #9fe4eb,
  300: #78d9e2,
  400: #5bd1dc,
  500: #3EC9D6,
  600: #38c3d1,
  700: #30bccc,
  800: #28b5c6,
  900: #1ba9bc,
  A100: #f1fdff,
  A200: #bef7ff,
  A400: #8bf0ff,
  A700: #72edff,
  contrast: (
    50: #e8f9fa,
    100: #1ba9bc,
    200: #28b5c6,
    300: #30bccc,
    400: #38c3d1,
    500: #3EC9D6,
    600: #5bd1dc,
    700: #78d9e2,
    800: #9fe4eb,
    900: #c5eff3,
    A100: #f1fdff,
    A200: #bef7ff,
    A400: #8bf0ff,
    A700: #72edff
  )
);
