* {
  margin: 0px;

  &:focus {
    outline: none;
  }
}

body {
  background-color: var(--accent-100);
  font-feature-settings: 'salt' !important;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    outline: none;
  }

  &:not([href]) {
    color: inherit;
  }
}

ul {
  list-style: none;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.flex-inline {
  display: inline-flex !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid {
  display: grid !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.float-end {
  float: right;
}

.float-start {
  float: left;
}

.text-wrap {
  white-space: pre-wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.align-item-start {
  align-items: start !important;
}

.align-item-center {
  align-items: center !important;
}

.align-item-end {
  align-items: flex-end !important;
}

.text-center {
  text-align: center !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.w-100 {
  width: 100% !important;
}

.border {
  border: 1px solid var(--accent-300);
}

.border-50 {
  border-radius: 50%;
}

.text-muted {
  color: var(--accent-600);
}

.text-white {
  color: $white;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/// adjust position when applying bade on custom elements
.mat-badge-content {
  // inside div
  .badge-on-text & {
    right: auto !important;
  }

  // inside button
  .badge-on-button & {
    right: 0px !important;
    top: -2px !important;
  }
}

// table box shadow
.mat-mdc-table.mat-elevation-z8 {
  box-shadow: none !important;
}

/*
  drop down style
*/
.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.4rem;
  font-size: 0.9rem;
  border-radius: 6px;
  border-color: var(--accent-300);
}

// end

// all table in user status in use
.user-status {
  font-size: 0.75em !important;
  padding: 0.25em 0.9em;
  display: inline-block;
  border-radius: 8px;

  &.monthly {
    border: none;
  }
}

// all theme in use multi images in one row
.user-group {
  display: inline-flex;
  align-items: center;

  .avatar,
  img {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border: 2px solid $white;

    + .avatar,
    + img {
      margin-left: -14px;
    }

    &:hover {
      z-index: 5;
    }
  }
}

// this style use all theme while use ul and li
.list-group-flush {
  padding-left: 0px;

  li {
    padding: 16px 24px;
  }
}

// customer page css details and edit tabs
.customer-details-header {
  padding: 20px 20px 0px;

  .modal-close {
    &:hover {
      background: var(--warn-50);
      color: var(--warn-500);
    }
  }
}

.customer-details-card {
  overflow-y: auto;

  .personal-info {
    .customer-status {
      div {
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0px 8px;
        border-radius: 8px;
      }
    }

    .border-side {
      border-right: 1px solid var(--accent-300);
      border-left: 1px solid var(--accent-300);
    }
  }

  .personal-details {
    .list-group-flush {
      .list-group-item {
        &:not(:last-child) {
          border-bottom: 1px solid var(--accent-300);
        }
      }
    }
  }
}

.customer-edit-card {
  .customer-edit-header {
    &:hover {
      background: var(--warn-50);
      color: var(--warn-500);
    }
  }

  .w-75 {
    width: 75%;
  }

  .card-footer {
    padding: 20px;
    border-top: 1px solid var(--accent-300);

    a:hover {
      background: var(--warn-50);
    }
  }
}

//  end

.user-avatar {
  width: 40px;
  border-radius: 50%;
}

.pc-icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
}

.list-inline {
  padding-left: 0px;
  list-style: none;

  .list-inline-item {
    display: inline-block;
  }
}

.close-drawer {
  &:hover {
    background: var(--primary-50);
    color: var(--primary-500);
  }
}

// table for expand-collapse
.mdc-data-table__content {
  td {
    &.collapse_data {
      padding: 0px;
    }
  }
}

// mat dialog
.custom-modalBox {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
  }
}

// mat card
.mat-mdc-card {
  &.user-account-card {
    border: none !important;
    box-shadow: none !important;
  }

  &.slider-card {
    width: 420px;
    display: block;
    margin-bottom: 0px;
  }

  &.price-card-2 {
    background: transparent;
    border: none !important;
  }

  &.block {
    box-shadow: none !important;
  }

  .mat-mdc-card-header {
    &.card-header {
      padding: 10px;
      border-bottom: 1px solid var(--accent-300);
    }
  }
}

// authentication in code verification input otp text align
.otp-input {
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    text-align: center;
  }
}

// dark-mode
body.dark-mode {
  //apexchart in dark-mode
  .apexcharts-legend-text {
    color: $white !important;
  }

  text {
    fill: $white !important;
  }

  .apexcharts-tooltip {
    background: var(--accent-300) !important;
    color: $white !important;

    &.apexcharts-theme-light .apexcharts-tooltip-title {
      background: var(--accent-300) !important;
    }
  }

  .apexcharts-canvas line {
    stroke: transparent !important;
  }

  /*  drop down style  */
  .form-select-sm,
  .select-item {
    background-color: var(--accent-400);
    color: var(--accent-900);
  }

  //quill editor
  .ql-editor.ql-blank::before {
    color: $white !important;
  }

  .NgxEditor__MenuBar {
    background: var(--accent-200);
  }

  .NgxEditor {
    background: var(--accent-200);
    color: $white;
    border: 1px solid $white;
  }
}

// ============================
//     avatar css start
// ============================

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;

  font: {
    size: 18px;
    weight: 600;
  }

  &.hover {
    &:hover {
      background: var(--accent-100);
      color: var(--accent-500);
    }
  }

  &.avatar-xxs {
    width: 16px;
    height: 16px;
    font-size: 14px;
    border-radius: 12px;
  }

  &.avatar-small {
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 50%;
  }

  &.avatar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px;
    border-radius: 12px;
  }

  &.avatar-s {
    width: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 12px;
  }

  &.avatar-l {
    width: 64px;
    height: 64px;
    font-size: 20px;
    border-radius: 12px;
  }

  &.avatar-xl {
    width: 72px;
    height: 72px;
    font-size: 24px;
    border-radius: 50%;
  }

  &.avatar-square {
    border-radius: 4px;
    border: 2px solid;
  }

  &.avatar-icon {
    border: 2px solid var(--accent-300);

    > img {
      width: 60%;
    }
  }
}

.chat-avatar {
  display: inline-flex;

  img {
    border-radius: 50%;
  }
}

// avatar css end

.table-responsive {
  overflow-x: auto;
}

.container {
  padding: 0px 12px;

  @media (min-width: 576px) {
    max-width: 540px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    max-width: 720px;
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
    margin: 0 auto;
  }

  @media (min-width: 1600px) {
    max-width: 1540px;
    margin: 0 auto;
  }
}
