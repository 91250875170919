// =======================================
//     List of variables for layout
// =======================================

$header-height: 74px;
$sidebar-width: 280px;
$sidebar-collapsed-width: 60px;
$sidebar-collapsed-active-width: 300px;

$header-background: rgba(248, 249, 250, 0.7);
$sidebar-shadow: none;

$border-radius: 8px;
